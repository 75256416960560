input[type=checkbox][data-switch="true"] {
    height: 0;
    width: 0;
    visibility: hidden;
}

label[data-switch="true"] {
    cursor: pointer;
    text-indent: -9999px;
    width: 70px;
    height: 35px;
    background: #2D9596;
    display: block;
    border-radius: 100px;
    position: relative;
}

label[data-switch="true"]:after {
    content: '';
    position: absolute;
    top: 3.5px;
    left: 3.5px;
    width: 27.5px;
    height: 27.5px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

input[data-switch="true"]:checked + label {
    background: #2D9596;
}

input[data-switch="true"]:checked + label:after {
    left: calc(100% - 3.5px);
    transform: translateX(-100%);
}

label[data-switch="true"]:active:after {
    width: 20px;
}